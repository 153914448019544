<template>
    <v-card min-height="500px">
        <v-card-text>
            <ValidationObserver ref="observer" v-slot="{}">
                <v-form>
                    <!-- Preferences Section -->
                    <v-row dense>
                        <v-col cols="12">
                            <CustomLabel for="timezone" label="Timezone" />
                            <CustomSelectField
                                id="timezone"
                                inputIcon="mdi-map-clock"
                                inputPlaceholder="Timezone"
                                :inputValue="user.settings.timezone_id"
                                :optionItems="timeZones"
                                optionText="name"
                                optionValue="id"
                                @change="
                                    updateForm(
                                        $event,
                                        'user.settings.timezone_id'
                                    )
                                "
                            />
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col cols="12" sm="6">
                            <CustomLabel
                                for="date_formats"
                                label="Date Format"
                            />
                            <CustomSelectField
                                id="date_formats"
                                inputMultiple
                                inputPlaceholder="Date Format"
                                :inputValue="user.settings.date_format"
                                :optionItems="dateFormats"
                                optionText="style"
                                optionValue="id"
                                @change="
                                    updateForm(
                                        $event,
                                        'user.settings.date_format'
                                    )
                                "
                            />
                        </v-col>

                        <v-col cols="12" sm="6">
                            <CustomLabel
                                for="time_formats"
                                label="Time Format"
                            />
                            <CustomSelectField
                                id="time_formats"
                                inputPlaceholder="Time Format"
                                :inputValue="user.settings.time_format"
                                :optionItems="timeFormats"
                                optionText="style"
                                optionValue="id"
                                @change="
                                    updateForm(
                                        $event,
                                        'user.settings.time_format'
                                    )
                                "
                            />
                        </v-col>
                    </v-row>

                    <!-- Button Section -->
                    <v-row dense class="mt-4">
                        <v-col>
                            <CustomButton
                                btnLabel="Cancel"
                                tooltipMsg="Cancel"
                                @click="hideDialog"
                            />
                            <CustomButton
                                btnColour="primary"
                                btnLabel="Save"
                                tooltipMsg="Save"
                                @click="updateItem(user)"
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </ValidationObserver>
        </v-card-text>
    </v-card>
</template>


<script>
import { mapState } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { dateFormats, timeFormats, timeZones } from "@/services/OptionMappers";

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});

export default {
    computed: {
        ...mapState("usersAdminMgmt", ["user"]),
    },
    data: () => ({
        dateFormats: dateFormats,
        timeFormats: timeFormats,
        timeZones: timeZones,
    }),
    methods: {
        hideDialog() {
            this.$store.commit("usersAdminMgmt/HIDE_VIEW_OPS_DIALOG");
        },
        updateForm(event, form) {
            if (event instanceof InputEvent) {
                this.$store.commit("usersAdminMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event.target.value,
                    },
                });
                return;
            } else if (
                event instanceof String ||
                typeof event == "boolean" ||
                typeof event == "number" ||
                typeof event == "string"
            ) {
                this.$store.commit("usersAdminMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event,
                    },
                });
                return;
            } else {
                return;
            }
        },
        async updateItem(payload) {
            let valid = this.$refs.observer.validate();

            if (!valid) {
                return;
            }

            let response = await this.$store.dispatch(
                "usersAdminMgmt/updateItem",
                {
                    id: payload.id,
                    user: {
                        settings: {
                            timezone_id: payload.settings.timezone_id,
                            date_format: payload.settings.date_format,
                            time_format: payload.settings.time_format,
                        },
                    },
                }
            );

            if (response) {
                this.$refs.observer.reset();
            }
        },
    },
    name: "TabUserSettings",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"min-height":"500px"}},[_c('v-card-text',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-form',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('CustomLabel',{attrs:{"for":"timezone","label":"Timezone"}}),_c('CustomSelectField',{attrs:{"id":"timezone","inputIcon":"mdi-map-clock","inputPlaceholder":"Timezone","inputValue":_vm.user.settings.timezone_id,"optionItems":_vm.timeZones,"optionText":"name","optionValue":"id"},on:{"change":function($event){return _vm.updateForm(
                                    $event,
                                    'user.settings.timezone_id'
                                )}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('CustomLabel',{attrs:{"for":"date_formats","label":"Date Format"}}),_c('CustomSelectField',{attrs:{"id":"date_formats","inputMultiple":"","inputPlaceholder":"Date Format","inputValue":_vm.user.settings.date_format,"optionItems":_vm.dateFormats,"optionText":"style","optionValue":"id"},on:{"change":function($event){return _vm.updateForm(
                                    $event,
                                    'user.settings.date_format'
                                )}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('CustomLabel',{attrs:{"for":"time_formats","label":"Time Format"}}),_c('CustomSelectField',{attrs:{"id":"time_formats","inputPlaceholder":"Time Format","inputValue":_vm.user.settings.time_format,"optionItems":_vm.timeFormats,"optionText":"style","optionValue":"id"},on:{"change":function($event){return _vm.updateForm(
                                    $event,
                                    'user.settings.time_format'
                                )}}})],1)],1),_c('v-row',{staticClass:"mt-4",attrs:{"dense":""}},[_c('v-col',[_c('CustomButton',{attrs:{"btnLabel":"Cancel","tooltipMsg":"Cancel"},on:{"click":_vm.hideDialog}}),_c('CustomButton',{attrs:{"btnColour":"primary","btnLabel":"Save","tooltipMsg":"Save"},on:{"click":function($event){return _vm.updateItem(_vm.user)}}})],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }